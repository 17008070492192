export default [
    {
      label: 'Policy',
      value: 'policy',
    },
    {
      label: 'Recycling',
      value: 'recycling',
    },
    {
      label: 'Gigafactory',
      value: 'gigafactory',
    },
    {
      label: 'Criticality',
      value: 'criticality',
    },
    {
      label: 'Event',
      value: 'event',
    },
    {
      label: 'Social',
      value: 'social',
    },
    {
      label: 'Publication',
      value: 'publication',
    },
  ];
  